import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { BYPASS_MSAL } from './authguard/auth-interceptor';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getjobLovs(): Observable<{ data: any[] }> {
    return this.http.get<{ data: any[] }>(`${this.apiUrl}/job/lovs`);
  }

  getJobById(jobId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/job/${jobId}`);
  }

  jobSearch(data: any): Observable<any> {
    let params = new HttpParams();
    if(data?.EmploymentTypeFilter!=null)
    data?.EmploymentTypeFilter?.forEach((id:any) => {
      params = params.append('EmploymentTypeFilter.Ids', id);
    });
    if(data?.JobCategoryFilter!=null)
    data?.JobCategoryFilter?.forEach((id:any) => {
      params = params.append('JobCategoryFilter.Ids',id);
    });
    if (data.SearchText!=null) {
      params = params.append('SearchText', data.SearchText);
    }
    if (data.SkipCount!=null) {
      params = params.append('SkipCount', data?.SkipCount);
    }
    if (data.MaxResultCount!=null) {
      params = params.append('MaxResultCount', data?.MaxResultCount);
    }
    return this.http.get<any>(`${this.apiUrl}/job/jobseeker/list`, {
      params: params,
      context: new HttpContext().set(BYPASS_MSAL, true),
    });
  }

  getSearchJobs(sorting: string, skipCount: number, searchText: string,count:number):Observable<{data:any[]}>{
    return this.http.get<{data:any[]}>(`${this.apiUrl}/job/jobseeker/list`,{ params: new HttpParams()
      .set("sorting", sorting.toString())
      .set("skipCount", skipCount)
      .set("searchText", searchText.toString())
      .set("maxResultCount",count)
    })
  }

  createJob(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/job`, data);
  }

  getEmployerDetails(id:number):Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/employer/${id}`)
  }

  applyJob(data: any) : Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/jobapplication`, data);
  }

  getAllResumes(userId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/user/resume/list/${userId}`);
  }
  jobChangeStatus(data:any){
    return this.http.patch<any>(`${this.apiUrl}/job/${data.id}/change/${data.statusId}/status`,{})
  }
  getUserSavedJobs(userId:string,params:any){
    return this.http.get<any>(`${this.apiUrl}/jobapplication/jobseeker/${userId}/saved`,{params:params})
  }
  saveJobs(data:any){
    return this.http.post<any>(`${this.apiUrl}/jobsave`,data)
  }
  removeSavedJobs(data:string[]){
    return this.http.delete<any>(`${this.apiUrl}/jobsave`,{body:data})
  }
}
